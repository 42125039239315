import { Component, NgZone, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import OneSignal from 'onesignal-cordova-plugin';
import { Capacitor } from '@capacitor/core';
import { NavController, Platform } from '@ionic/angular';
import { GlobalEventService } from './services/global-event.service';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { register } from 'swiper/element/bundle';
import { SplashScreen } from '@capacitor/splash-screen';
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';

register();

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(
    private readonly router: Router,
    private readonly nav: NavController,
    private readonly event: GlobalEventService,
    private readonly platform: Platform,
    private readonly zone: NgZone
  ) {
    this.platform.ready().then(() => {
      if (Capacitor.getPlatform() !== 'web') {
        this.oneSignalInit();
      }
      SplashScreen.hide().then(() => console.log(`Splashscreen hidden`));
      GoogleAuth.initialize();
      this.initializeApp();
    });
  }

  initializeApp() {
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      this.zone.run(() => {
        const domain = 'topmai.com.mx';
        const pathArray = event.url.split(domain);
        const appPath = pathArray.pop();
        if (appPath) {
          this.router.navigateByUrl(appPath);
        }
      });
    });
  }

  oneSignalInit() {
  }
  ngOnInit() {
   
  } private redirectIfMobile() {
    const isMobile = this.platform.is('mobile') || this.platform.is('cordova');
    if (isMobile) {
      window.location.href = 'https://topmai.com.mx';
    }
  }
}
