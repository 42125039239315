import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

 
export const INTRO_KEY = 'intro-seen';
 
@Injectable({
  providedIn: 'root'
})
export class IntroGuard  {
 
  constructor(private router: Router) { }
  
  async canLoad(): Promise<boolean> {
      const hasSeenIntro = localStorage.getItem(INTRO_KEY);      
      if (hasSeenIntro == 'yes') {
        return true;
      } else {
        this.router.navigateByUrl('/init', { replaceUrl:true });
        return false;
      }
  }
}