import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { IntroGuard } from './guards/intro.guard';

const routes: Routes = [
  {
    path: 'tabs',
    loadChildren: () =>
      import('./tabs/tabs.module').then((m) => m.TabsPageModule),
    canLoad: [IntroGuard],
  },
  {
    path: '',
    redirectTo: 'tabs',
    pathMatch: 'full',
  },
  {
    path: 'chatoff',
    loadChildren: () =>
      import('./chat-bottom-toolbar/chat-bottom-toolbar.module').then(
        (m) => m.ChatBottomToolbarPageModule
      ),
  },
  {
    path: 'add-categories',
    loadChildren: () =>
      import('./chat/add-categories/add-categories.module').then(
        (m) => m.AddCategoriesPageModule
      ),
  },
  {
    path: 'wallet',
    loadChildren: () =>
      import('./chat/wallet/wallet.module').then((m) => m.WalletPageModule),
  },
  {
    path: 'discover',
    loadChildren: () =>
      import('./chat/discover/discover.module').then(
        (m) => m.DiscoverPageModule
      ),
  },
  {
    path: 'home/item-two/:id',
    loadChildren: () =>
      import('./home/view-one-item/view-one-item.module').then(
        (m) => m.ViewOneItemPageModule
      ),
  },
  {
    path: 'home/seller-profile-model/:id',
    loadChildren: () =>
      import('./home/seller-profile-model/seller-profile-model.module').then(
        (m) => m.SellerProfileModelPageModule
      ),
  },
  {
    path: 'submit-stage',
    loadChildren: () =>
      import('./chat/submit-stage/submit-stage.module').then(
        (m) => m.SubmitStagePageModule
      ),
  },
  {
    path: 'album-model',
    loadChildren: () =>
      import('./chat/album-model/album-model.module').then(
        (m) => m.AlbumModelPageModule
      ),
  },
  {
    path: 'profile1',
    loadChildren: () =>
      import('./chat/profile1/profile1.module').then(
        (m) => m.Profile1PageModule
      ),
  },
  {
    path: 'transferencia',
    loadChildren: () =>
      import('./chat/transferencia/transferencia.module').then(
        (m) => m.TransferenciaPageModule
      ),
  },

  {
    path: 'transfer',
    loadChildren: () =>
      import('./chat/transfer/transfer.module').then(
        (m) => m.TransferPageModule
      ),
  },
  {
    path: 'transfer-detail/:profileId',
    loadChildren: () =>
      import('./chat/transfer/transfer-detail/transfer-detail.module').then(
        (m) => m.TransferDetailPageModule
      ),
  },
  {
    path: 'form-paquete/:from/:to',
    loadChildren: () =>
      import('src/app/order-wizard/form-paquete/form-paquete.module').then(
        (m) => m.FormPaquetePageModule
      ),
  },
  {
    path: 'form-paqueterias/:from/:to/:data',
    loadChildren: () =>
      import(
        'src/app/order-wizard/form-paqueterias/form-paqueterias.module'
      ).then((m) => m.FormPaqueteriasPageModule),
  },
  {
    path: 'order-wizard',
    loadChildren: () =>
      import('src/app/order-wizard/order-wizard.module').then(
        (m) => m.OrderWizardPageModule
      ),
  },
  {
    path: 'my-orders',
    loadChildren: () =>
      import('./my-orders/my-orders.module').then((m) => m.MyOrdersPageModule),
  },
  {
    path: 'favorites',
    loadChildren: () =>
      import('./favorites/favorites.module').then((m) => m.FavoritesPageModule),
  },
  {
    path: 'offers/:option',
    loadChildren: () =>
      import('./about-me/offers/offers.module').then((m) => m.OffersPageModule),
  },
  {
    path: 'sells/:option',
    loadChildren: () =>
      import('./about-me/sells/sells.module').then((m) => m.SellsPageModule),
  },
  {
    path: 'sell-detail/:id/:option',
    loadChildren: () =>
      import('./about-me/order-state/order-state.module').then(
        (m) => m.OrderStatePageModule
      ),
  },
  {
    path: 'offer-detail/:id/:option',
    loadChildren: () =>
      import('./about-me/offer-detail/offer-detail.module').then(
        (m) => m.OfferDetailPageModule
      ),
  },
  {
    path: 'vehiculos',
    loadChildren: () =>
      import('./vehiculos/vehiculos.module').then((m) => m.VehiculosPageModule),
  },
  {
    path: 'order-wizard',
    loadChildren: () =>
      import('./order-wizard/order-wizard.module').then(
        (m) => m.OrderWizardPageModule
      ),
  },
  {
    path: 'monedero',
    loadChildren: () =>
      import('./chat/monedero/monedero.module').then(
        (m) => m.MonederoPageModule
      ),
  },
  {
    path: 'history',
    loadChildren: () =>
      import('./chat/wallet/history/history.module').then(
        (m) => m.HistoryPageModule
      ),
  },

  {
    path: 'transaction-detail/:id',
    loadChildren: () =>
      import('./chat/wallet/transaction-detail/transaction-detail.module').then(
        (m) => m.TransactionDetailPageModule
      ),
  },
  {
    path: 'settings',
    loadChildren: () =>
      import('./chat/wallet/settings/settings.module').then(
        (m) => m.SettingsPageModule
      ),
  },
  {
    path: 'register',
    loadChildren: () =>
      import('./chat/contact-list/register/register.module').then(
        (m) => m.RegisterPageModule
      ),
  },
  {
    path: 'balance',
    loadChildren: () =>
      import('./chat/balance/balance.module').then((m) => m.BalancePageModule),
  },
  {
    path: 'addcard',
    loadChildren: () =>
      import('./chat/addcard/addcard.module').then((m) => m.AddcardPageModule),
  },
  {
    path: 'chat/resolve-center',
    loadChildren: () =>
      import('./chat/resolve-center/resolve-center.module').then(
        (m) => m.ResolveCenterPageModule
      ),
  },
  {
    path: 'my-cards',
    loadChildren: () =>
      import('./my-cards/my-cards.module').then((m) => m.MyCardsPageModule),
  },
  {
    path: 'video-home',
    loadChildren: () =>
      import('./video-home/video-home.module').then(
        (m) => m.VideoHomePageModule
      ),
  },
  {
    path: 'authentication',
    loadChildren: () =>
      import('./authentication/authentication.module').then(
        (m) => m.AuthenticationPageModule
      ),
  },
  {
    path: 'discovertwo/:id',
    loadChildren: () =>
      import('./chat/discovertwo/discovertwo.module').then(
        (m) => m.DiscovertwoPageModule
      ),
  },
  {
    path: 'privacidad',
    loadChildren: () =>
      import('./chat/privacidad/privacidad.module').then(
        (m) => m.PrivacidadPageModule
      ),
  },
  {
    path: 'add-publication/:id',
    loadChildren: () =>
      import('./add-publication/add-publication.module').then(
        (m) => m.AddPublicationPageModule
      ),
  },
  {
    path: 'add-publication',
    loadChildren: () =>
      import('./add-publication/add-publication.module').then(
        (m) => m.AddPublicationPageModule
      ),
  },
  {
    path: 'pageneedloggin',
    loadChildren: () =>
      import('./globalpagesmodals/pageneedloggin/pageneedloggin.module').then(
        (m) => m.PageneedlogginPageModule
      ),
  },
  {
    path: 'modalpageneedloggin',
    loadChildren: () =>
      import(
        './globalpagesmodals/modalpageneedloggin/modalpageneedloggin.module'
      ).then((m) => m.ModalpageneedlogginPageModule),
  },
  {
    path: 'publication-detail/:id',
    loadChildren: () =>
      import('./publication/publication-detail/publication-detail.module').then(
        (m) => m.PublicationDetailPageModule
      ),
  },
  {
    path: 'buy',
    loadChildren: () =>
      import('./publication/buy/buy.module').then(
        (m) => m.BuyPageModule
      ),
  },
  {
    path: 'popover',
    loadChildren: () =>
      import('./popover/popover.module').then((m) => m.PopoverPageModule),
  },
  {
    path: 'modalbusquedaavanzada',
    loadChildren: () =>
      import(
        './globalpagesmodals/modalbusquedaavanzada/modalbusquedaavanzada.module'
      ).then((m) => m.ModalbusquedaavanzadaPageModule),
  },
  {
    path: 'singlesubcategory',
    loadChildren: () =>
      import('./singlesubcategory/singlesubcategory.module').then(
        (m) => m.SinglesubcategoryPageModule
      ),
  },
  {
    path: 'loginchatv2',
    loadChildren: () =>
      import('./pages/login/login.module').then((m) => m.LoginPageModule),
  },
  {
    path: 'chat',
    loadChildren: () =>
      import('./chat-bottom-toolbar/chat-bottom-toolbar.module').then(
        (m) => m.ChatBottomToolbarPageModule
      ),
  },
  {
    path: 'popup-cart',
    loadChildren: () =>
      import('./popup-cart/popup-cart.module').then(
        (m) => m.PopupCartPageModule
      ),
  },
  {
    path: 'add-post',
    loadChildren: () =>
      import('./chat/add-post/add-post.module').then(
        (m) => m.AddPostPageModule
      ),
  },
  {
    path: 'modal-swipe',
    loadChildren: () =>
      import('./modal-swipe/modal-swipe.module').then(
        (m) => m.ModalSwipePageModule
      ),
  },
  {
    path: 'popover-likes',
    loadChildren: () =>
      import('./popover-likes/popover-likes.module').then(
        (m) => m.PopoverLikesPageModule
      ),
  },
  {
    path: 'popover-coments',
    loadChildren: () =>
      import('./popover-coments/popover-coments.module').then(
        (m) => m.PopoverComentsPageModule
      ),
  },
  {
    path: 'qr-generate',
    loadChildren: () =>
      import('./chat/qr-generate/qr-generate.module').then(
        (m) => m.QrGeneratePageModule
      ),
  },
  {
    path: 'qr-scanner',
    loadChildren: () =>
      import('./chat/qr-scanner/qr-scanner.module').then(
        (m) => m.QrScannerPageModule
      ),
  },
  {
    path: 'create-pin',
    loadChildren: () =>
      import('./create-pin/create-pin.module').then(
        (m) => m.CreatePinPageModule
      ),
  },
  {
    path: 'validate-pin',
    loadChildren: () =>
      import('./validate-pin/validate-pin.module').then(
        (m) => m.ValidatePinPageModule
      ),
  },
  {
    path: 'init',
    loadChildren: () =>
      import('./init/init.module').then((m) => m.InitPageModule),
  },
  {
    path: 'profile-model',
    loadChildren: () =>
      import('./about-me/profile-model/profile-model.module').then(
        (m) => m.ProfileModelPageModule
      ),
  },
  {
    path: 'product-zoom',
    loadChildren: () =>
      import('./product-zoom/product-zoom.module').then(
        (m) => m.ProductZoomPageModule
      ),
  },
  {
    path: 'modal-politics',
    loadChildren: () =>
      import('./modal-politics/modal-politics.module').then(
        (m) => m.ModalPoliticsPageModule
      ),
  },
  {
    path: 'info-topmai',
    loadChildren: () =>
      import('./info-topmai/info-topmai.module').then(
        (m) => m.InfoTopmaiPageModule
      ),
  },
  {
    path: 'popover-post',
    loadChildren: () =>
      import('./popover-post/popover-post.module').then(
        (m) => m.PopoverPostPageModule
      ),
  },
  {
    path: 'modal-eula',
    loadChildren: () =>
      import('./modal-eula/modal-eula.module').then(
        (m) => m.ModalEulaPageModule
      ),
  },
  {
    path: 'withdraw-balance',
    loadChildren: () =>
      import('./chat/withdraw-balance/withdraw-balance.module').then(
        (m) => m.WithdrawBalancePageModule
      ),
  },
  {
    path: 'add-bankaccount',
    loadChildren: () =>
      import('./chat/add-bankaccount/add-bankaccount.module').then(
        (m) => m.AddBankaccountPageModule
      ),
  },
  {
    path: 'confirm-pin',
    loadChildren: () =>
      import('./chat/confirm-pin/confirm-pin.module').then(
        (m) => m.ConfirmPinPageModule
      ),
  },
  {
    path: 'status-withdraw',
    loadChildren: () =>
      import('./chat/status-withdraw/status-withdraw.module').then(
        (m) => m.StatusWithdrawPageModule
      ),
  },
  {
    path: 'modal-select-pay',
    loadChildren: () =>
      import('./chat/modal-select-pay/modal-select-pay.module').then(
        (m) => m.ModalSelectPayPageModule
      ),
  },
  {
    path: 'modal-offer',
    loadChildren: () =>
      import('./chat/modal-offer/modal-offer.module').then(
        (m) => m.ModalOfferPageModule
      ),
  },
  {
    path: 'modal-offer-option',
    loadChildren: () =>
      import('./chat/modal-offer-option/modal-offer-option.module').then(
        (m) => m.ModalOfferOptionPageModule
      ),
  },
  {
    path: 'shipping-address-model',
    loadChildren: () =>
      import(
        './about-me/shipping-address-model/shipping-address-model.module'
      ).then((m) => m.ShippingAddressModelPageModule),
  },
  {
    path: 'confirmation-popover',
    loadChildren: () =>
      import(
        './about-me/confirmation-popover/confirmation-popover.module'
      ).then((m) => m.ConfirmationPopoverPageModule),
  },
  {
    path: 'bankaccounts-list',
    loadChildren: () =>
      import('./chat/bankaccounts-list/bankaccounts-list.module').then(
        (m) => m.BankaccountsListPageModule
      ),
  },
  {
    path: 'popover-calification',
    loadChildren: () =>
      import('./popover-calification/popover-calification.module').then(
        (m) => m.PopoverCalificationPageModule
      ),
  },
  {
    path: 'popover-devolution',
    loadChildren: () =>
      import('./popover-devolution/popover-devolution.module').then(
        (m) => m.PopoverDevolutionPageModule
      ),
  },
  {
    path: 'modal-tracking',
    loadChildren: () =>
      import('./chat/modal-tracking/modal-tracking.module').then(
        (m) => m.ModalTrackingPageModule
      ),
  },
  {
    path: 'qr-send',
    loadChildren: () =>
      import('./chat/wallet/qr-send/qr-send.module').then(
        (m) => m.QrSendPageModule
      ),
  },
  {
    path: 'identity-validation',
    loadChildren: () =>
      import('./about-me/identity-validation/identity-validation.module').then(
        (m) => m.IdentityValidationPageModule
      ),
  },
  {
    path: 'modal-cards',
    loadChildren: () =>
      import('./chat/modal-cards/modal-cards.module').then(
        (m) => m.ModalCardsPageModule
      ),
  },
  {
    path: 'modal-store',
    loadChildren: () =>
      import('./chat/modal-store/modal-store.module').then(
        (m) => m.ModalStorePageModule
      ),
  },
  {
    path: 'modal-request-store',
    loadChildren: () =>
      import('./chat/modal-request-store/modal-request-store.module').then(
        (m) => m.ModalRequestStorePageModule
      ),
  },
  {
    path: 'list-req-store',
    loadChildren: () =>
      import('./chat/list-req-store/list-req-store.module').then(
        (m) => m.ListReqStorePageModule
      ),
  },
  {
    path: 'us',
    loadChildren: () =>
      import('./us/us.module').then(
        (m) => m.UsPageModule
      ),
  },
  {
    path: 'contact',
    loadChildren: () =>
      import('./contact/contact.module').then(
        (m) => m.ContactPageModule
      ),
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
